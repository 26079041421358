$( document ).ready(function() {
  // ON DOCUMENT READY
});

$(document).on('click', '.mobile_menu', function(e){
  $('.bar1, .bar2, .bar3').toggleClass("change");
  $(".navigation_links").toggleClass("visible", 1000);
  // $(".navigation_links").slideToggle(300);
});

window.show_editor_field = function() {
  center_editor_field();
  var editor_field = $('#editor_field');  
  editor_field.fadeIn(100);
}

window.clear_editor_field = function() {
  var editor_field = $('#editor_field');
  editor_field.removeClass('active_window');
  editor_field.html('');
  editor_field.fadeOut(100);
}

window.center_editor_field = function() {
  var editor_field = $('#editor_field');
  var mtop = Math.max(($(window).height() - editor_field.height()) / 2, 0);
  editor_field.css('top', mtop + 'px')
}

$(document).keydown(function(e){
  var code = e.keyCode || e.which;
  if (code == 27) {
    clear_editor_field();
  }
});